using System.Collections.Generic;
using Bridge.Navigation;

namespace Bridge.Spaf
{
    public abstract class LoadableViewModel : ViewModelBase, IAmLoadable
    {
        protected List<IViewModelLifeCycle> Partials { get; private set; }

        public virtual void OnLoad(Dictionary<string, object> parameters)
        {
            base.ApplyBindings();
            global::Bridge.Script.ToTemp("key1",this.Partials)!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<List<IViewModelLifeCycle>>("key1").ForEach((global::System.Action<global::Bridge.Spaf.IViewModelLifeCycle>)(f=> f.Init(parameters)))):null;
        }

        public virtual void OnLeave()
        {
            global::Bridge.Script.ToTemp("key2",this.Partials)!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<List<IViewModelLifeCycle>>("key2").ForEach((global::System.Action<global::Bridge.Spaf.IViewModelLifeCycle>)(f=>f.DeInit()))):null;
            base.RemoveBindings();
        }

    
private List<IViewModelLifeCycle> __Property__Initializer__Partials=new List<IViewModelLifeCycle>();}
}